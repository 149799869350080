.summary-card {
  height: 100%;

  .ant-card-body {
    min-height: 168px;
  }
  
  .summary-card-header {
    margin-bottom: 16px;
    color: #252525;
    min-height: 62px;

    h3 {
      margin-bottom: 6px;
      font-size: 16px;
    }
    
    .main-value {
      font-size: 24px;
      line-height: 1.3;
      font-weight: 600;
    }
  }
  
  .summary-card-content {
    display: flex;
    .sub-item {
      display: flex;
      flex-direction: column;
      color: #252525;
      flex: 1;
      min-width: 0;
      
      .label {
        font-size: 14px;
      }
      
      .value {
        font-size: 18px;
      }
    }
  }
  .ant-card {
    border-radius: 6px;
  }
  
  @media (max-width: 768px) {
    .summary-card-header {
      .main-value {
        font-size: 20px;
      }
    }
    
    .sub-item {
      font-size: 14px;
    }
  }
} 