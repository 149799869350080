@font-face {
  font-family: 'Nunito Sans Semi';
  src: url('../../assets/fonts/NunitoSans-VariableFont_YTLC.ttf');
}
.layout-pos{
  height: 100vh;
  overflow: hidden;
  *{
    font-family: 'Nunito Sans Semi';
  }
  .tab-nav{
    padding-top: 24px;
    text-align: center;
    position: sticky;
    top: 0;
    padding: 0 48px;
    margin: 0 auto;
    .ant-segmented{
      .ant-segmented-group{
        .ant-segmented-item{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px;
          width: 276px;
          color: #0D0E10;
          font-weight: 600;
          font-size: 18px;
          line-height: 48px;
          font-weight: bold;
          background-color: #f0f0f0;
          &.ant-segmented-item-selected{
            background-color: #cce3ff;
          }
        }
      }
    }
  }
  .main{
    padding: 0 48px;
    height: calc(100vh - 80px);
    overflow: scroll;
    overflow-x: auto;
  }
}