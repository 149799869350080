@import "../index.scss";

.menus-prices {
  .switch-text{
    margin-bottom: -10px;
    position: relative;
    .text{
      position: absolute;
      left: 60px;
      top: 9px;
    }
  }
  @include menus-card-style
}