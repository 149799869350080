@font-face {
  font-family: Roboto;
  font-weight: 400;
  src: url('../assets/fonts/roboto.woff2') format('woff2')
}

@mixin ant-form-item{
    .ant-form-item{
        margin-bottom: 0;

        &-row{
            margin-bottom: 16px;

            .ant-form-item-control{
                max-width: 100%;

                .ant-form-item-control-input{
                    width: 350px;
                    height: 48px;
                    @media (max-width: 767px) {
                        width: 330px;
                    }
                    &-content{
                        width: 100%;
                        height: 100%;

                        input, .ant-input-password{
                            height: 100%;

                            .ant-input-suffix svg{
                                cursor: pointer;
                            }
                        }
                    }
                }

                .ant-form-item-explain{
                    .ant-form-item-explain-error{
                        text-align: left;
                    }
                }
            }
        }
    }
}

@mixin reset-btn{
    .ant-btn{
        height: 48px;
        padding: 11.6px 24px;
    }
}
