.order-price-input-wrap {
  margin-top: 16px; 
}

.top-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px; 

  .order-price-warnings {
    div {
      transition: all 0.3s ease-in-out;
      overflow: hidden;

      // 默认提示样式
      &.default-order-price {
        color: #676767;
      }

      // 错误和警告共用红色样式
      &.error-order-price,
      &.warning-order-price {
        color: #E10013;
      }

      // 动画状态
      &.slide-in {
        opacity: 1;
        max-height: 24px;
      }
      &.slide-out {
        opacity: 0;
        max-height: 0;
      }
    }
  }
}


.order-price-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}


.order-price-warnings {
  position: relative;
  height: 24px;
  overflow: hidden;
  margin: 8px 0;

  .warning-message {
    position: absolute;
    width: 100%;
    transition: transform 0.3s ease, opacity 0.3s ease;
    color: #E10013;

    &.slide-in {
      transform: translateY(0);
      opacity: 1;
    }

    &.slide-out {
      transform: translateY(-100%);
      opacity: 0;
    }
  }
}


.order-price-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.rule-row-container {
  .order-price-row {
    position: relative;
    
    .anticon-close-circle {
      position: absolute;
      right: -50px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.order-price-row {
  display: flex; 
  align-items: center; 
  gap: 16px; 
  flex-grow: 0; 
  margin-bottom: 8px;
}

.order-price-button {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #2563EB; 
  border: none;
  padding: 16px;
}

.order-price-label {
  white-space: nowrap;
}

.order-price-input {
  margin: 0;
  height: 64px; 
  border-radius: 4px; 
  padding: 8px 16px; 
  box-sizing: border-box; 
}