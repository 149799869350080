.integrations {
  .title-h1{
    margin: 16px 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  }
  .connect-partners-list{
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    .item{
      display: flex;
      width: 340px;
      padding: 16px 24px;
      justify-content: space-between;
      border-radius: 8px;
      border: 1px solid var(--Outline-underline, #E8E8E8);
      .item-left{
        display: flex;
        align-items: center;
      }
      .img-logo{
        display: block;
        width: 24px;
        height: 24px;
      }
      .text{
        margin-left: 12px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}
.login-pop-up{
  font-size: 16px;
}
.popup-onboarding{
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  flex-direction: column;
  .title-h1{
    font-size: 18px;
    color: #252525;
    font-weight: 600;
  }
  .info, .text{
    font-size: 16px;
    line-height: 22px;
    color: #0d0e10;
  }
  .text{
    color: #616368;
  }
  .error{
    color: #e10013;
    font-size: 16px;
    line-height: 22px;
  }
}
.validating-menu{
  display: flex;
  gap: 8px;
  flex-direction: column;
  .success{
    color: #73D13D;
  }
  .error{
    color: #E10013;
  }
  .icon-status{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: 3px;
    &.disabled{
      width: 29px;
      height: 29px;
      font-size: 16px;
      color: #b8b8b8;
      border-radius: 50%;
      border: 2px solid #b8b8b8;
    }
  }
  .ant-spin-nested-loading .ant-spin-blur{
    opacity: 1;
  }
  .loading-icon-text{
    position: relative;
    .text{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      font-size: 16px;
      background-color: #1683e8;
      color: #fff;
      border-radius: 50%;
    }
  }
  .blue{
    color: #2563EB;
  }
  .item-menu-status{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2px;
    font-size: 16px;
    &.disabled{
      color: #8c8c8c;
    }
  }
}
.error-message{
  padding: 8px 16px;
  margin-bottom: 10px;
  background-color: #ffedea;
  font-size: 16px;
  font-weight: 400;
  color: #e10013;
  line-height: 22px;
}
.popup-footer-box{
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}