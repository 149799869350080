.payoutReportDetail {
  .titleWrap {
    height: 45px;
    border-bottom: 1px solid #E8E8E8;
    font-family: PingFang SC, serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0;
    color: #252525;

    .mainTitle {
      color: #55565E;
      cursor: pointer;

      &:hover {
        color: #252525;
      }
    }

    .split {
      margin: 0 4px;
      font-size: 18px;
    }

    .subTitle {
      margin-right: 6px;
    }

  }

  .reportDate {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    font-size: 14px;
    color: #252525;

  }

  // .summaryWrap {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   margin-top: 8px;
  //   padding: 16px 14px 12px 14px;
  //   background: #F2F2F2;
  //   border-radius: 4px;

  //   .separator {
  //     width: 1px;
  //     background-color: #C7C7C7;
  //     height: 33px;
  //     margin: auto;
  //   }

  //   .summaryItem {
  //     height: 53px;

  //     .name {
  //       color: #55565E;
  //       font-size: 15px;
  //     }

  //     .price {
  //       margin-top: 6px;
  //       font-weight: 400;
  //     }
  //   }
  // }
  .summaryWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    padding: 16px 14px 12px 14px;
    background: #F2F2F2;
    border-radius: 4px;
    flex-wrap: wrap; /* 允许子项换行 */
  
    .separator {
      width: 1px;
      background-color: #C7C7C7;
      height: 33px;
      margin: auto;
    }
  
    .summaryItem {
      height: 53px;
      flex: calc(33.33% - 10px); /* 在移动端每行显示两个项目 */
      box-sizing: border-box;
  
      .name {
        color: #55565E;
        font-size: 15px;
      }
  
      .price {
        margin-top: 6px;
        font-weight: 400;
      }
    }
  }
  
  /* PC端样式 */
  @media (min-width: 768px) {
    .summaryWrap {
      flex-direction: row; /* PC端保持水平布局 */
    }
  
    .summaryItem {
      flex: 1 !important;
    }
  }
  
  /* 移动端样式 */
  @media (max-width: 767px) {
    .summaryWrap {
      flex-direction: row; /* 移动端两列布局 */
    }
  
    .summaryItem {
      flex: 1 1 calc(33.33% - 10px) !important;
    }
    .summaryItem:nth-child(-n+3) {
      margin-bottom: 16px;
    }
  }

  .tableWrap {
    margin-top: 16px;

    .ant-table-thead {
      tr:first-child {
        & > *:first-child {
          padding-left: 14px;
          border-start-start-radius: 4px !important;
        }

        & > *:last-child {
          border-start-end-radius: 4px !important;
        }
      }

      .ant-table-cell {
        background: #F2F2F2;
        font-weight: normal;
        color: #55565E;
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        height: 58px;

        td:first-child {
          padding-left: 14px;
        }
      }

      .ant-table-cell {
        border-color: #DDDDDD;
      }
    }
  }
}