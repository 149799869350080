@import "../index.scss";

.menus-item-info {
  @include menus-card-style
}
.img-tips {
  font-size: 14px;
  line-height: 20px;
  color: #7d7d81;
  width: 400px;
}
.img-label{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}