.transaction-cash {
  .filter-wrap {
    display: flex;
    justify-content: space-between;
    border-radius: 4px;

    .ant-form {
      gap: 10px 0;

      .anticon-search {
        color: #BEBEBE;
      }
    }
    .right-wrap {
      display: flex;
      align-items: center;
    }
  }

  .table-wrap {
    margin-top: 12px;

    .empty-table {
      .ant-table-thead {
        .ant-table-cell {
          height: 39px;
        }
      }

      .ant-table-tbody {
        display: none !important;
      }

      .ant-pagination {
        display: none !important;
      }
    }
  }

  .action-wrap {
    position: fixed;
    right: 85px;
    top: 80px;
    z-index: 20;
  }
  @media (max-width: 767px) {
    .filter-wrap{
      .right-wrap{
        align-items: flex-end;
      }
    }
    .action-wrap{
      top: 65px;
      right: 15px;
    }
  }
}