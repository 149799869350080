.pre-modifier-edit {
  width: 100%;
  background-color: #F5F5F5;
  
  .card {
    background-color: #fff;
    padding: 16px 16px 32px 16px;
    margin: 24px;
  }

  .content {
    width: 100%;
  }

  .table-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .ant-form-item {
    margin-bottom: 0 !important; 
  }
}