.tip-custom-area{
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  &-item{
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    &-custom-tips{
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 50px;
      &-line{
        width: 150px;
        height: 20px;
        border-bottom: 1px solid #252525;
        margin-bottom: 10px;
      }
    }
  }
}