.ant-menu-submenu{
  z-index: 1051;
}
.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: rgba(255, 255, 255, 0.10) !important;
  margin: 0 4px !important;
}

.ant-menu-dark .ant-menu-item-selected {
  background-color: #CCE3FF !important;
  color: black;
}

.ant-menu-submenu-selected .ant-menu-submenu-title {
  background: rgba(255, 255, 255, 0.10) !important;
}

.ant-menu-item {
  padding-left: 16px !important;
}


.ant-menu-submenu-title {
  padding-left: 16px !important;
  margin-bottom: 0px !important;
  border-radius: 0 !important;
}

.cardContent {
  border-radius: 16px 0 0 16px;
  background-color: #fff;
}

.ant-layout-header  {
  border-radius: 16px 0 0;
}


.header-userName {
  display: flex;
  padding: 0 16px;
}

.header-userName > span {
  margin: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.downOut {
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 1px solid #222;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
  cursor: pointer;
  position: relative;
  top: -1px;
}

.middleLine {
  width: 1px;
  height: 20px;
  background: rgb(0 0 0 / 20%);
  position: relative;
  top: 8px;
  margin: 0 0 0 8px;
}

.menuIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  padding-right: 10px;
  position: relative;
  top: -2px;
}

.menuList {
  width: 240px;
  overflow: auto;
}

.menuListBox {
  width: 240px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 8px;
  font-size: 16px;
  .info{
    line-height: 22px;
    color: #313131;
  }
}
.help-box{
  width: 240px;
  font-size: 16px;
  .title{
    font-size: 18px;
  }
  .help-center{
    display: flex;
    justify-content: space-between;
  }
  .info{
    line-height: 22px;
    color: #313131;
    .anticon{
      margin-right: 8px;
    }
    &.btn{
      padding: 0 8px;
      height: 40px;
      line-height: 40px;
      a{
        display: block;
      }
    }
    &.btn:hover {
      background-color: #f9f9f9;
    }
  }
}
.iconfont{
  font-size: 20px !important;
}

.home-name {
  display: flex;
  align-items: center;
  padding-left: 10px;
  overflow: hidden;
  min-width: 200px;
  max-width: 400px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.MenuUnfoldOutlined{
  text-align: center;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
.ant-modal-body{
  padding-bottom:16px!important;
  padding-top:16px!important;
}

.ant-row ,
.ant-row-top{
  margin-left: 0!important;
  margin-right: 0!important;
}

.menuListBox:hover {
  background-color: #f9f9f9;
}

.menuListBox1:hover {
  background-color: #f9f9f9;
}

.ant-menu-submenu-selected .ant-menu-submenu-title{
  background-color: rgb(255 255 255 / 7%);
}

.ant-menu-submenu-popup .ant-menu-vertical{
  .ant-menu-item {
    width: 100%;
    margin: 8px 0;
  }
}

.ant-menu.ant-menu-sub.ant-menu-vertical{
  padding: 4px 8px;
}
.main-tab-title {
  display: flex;
  font-size: 18px;

  &:after {
    content: ' ';
    margin: 0 32px 0 16px;
    display: block;
    height: 28px;
    width: 1px;
    background: #DDD;
  }
}
.header{
  padding: 0;
  height: 64px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.08);
}
.select-btn{
  display: flex;
  align-items: center;
  height: 48px;
  line-height: 48px;
  // padding: 0 16px;
  border-radius: 4px;
  font-size: 14px;
  color: '#252525';
  cursor: pointer;
}
.snv-menu{
  overflow-y: auto;
  // max-height: 98vh;
}
.menuUnfoldOutBtn {
  font-size: 24px;
  padding-left: 8px;
}
.user-btn{
  color:'#555';
  cursor: 'pointer'
}
@media (max-width: 767px) {
  .bg-none{
    position: fixed;
    z-index: 999;
    top: 48px;
    bottom: 0;
    left: 200px;
    right: 0;
    background-color: rgba(0,0,0,0.8);
  }
  .MenuUnfoldOutlined{
    position: fixed;
    top: 10px;
    right: 10px;
    // background-color: #0D1F2D;
    background-color: #091D47;
  }
  .cardContent{
    border-radius: 0;
    padding-top: 48px;
  }
  .ant-layout-header{
    border-radius: 0;
  }
  .header{
    display: flex;
    justify-content: space-between;
    height: 48px;
    line-height: 48px;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 0 16px;
    align-items: center;
    // background-color: #0D1F2D;
    background-color: #091D47;
    .logo{
      padding: 0;
      img{
        height: 28px;
      }
    }
  }
  .header-userName{
    padding: 0;
  }
  .header-userName > span {
    margin: 0 8px;
  }
  .user-btn{
    color: #fff;
    font-size: 24px !important;
  }
  .ant-menu{
    padding-top: 48px;
  }

  .ant-menu-submenu-title, .ant-menu-item{
    padding-left: 10px !important;
  }
  .main-tab-title, .help-box .title{
    font-size: 16px;
  }
}


/* 针对Webkit浏览器（如Chrome, Safari） */
.snv-menu::-webkit-scrollbar {
  width: 12px; /* 滚动条宽度 */
}

.snv-menu {
  scrollbar-width: thin; /* 滚动条宽度 */
  scrollbar-color: #CCE3FF #091D47; /* 滚动条滑块和轨道颜色 */
}