.orderReportDetail {
  min-height: 100vh;
  padding: 0 24px;
  .titleWrap {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 10;
    height: 45px;
    margin-bottom: 12px;
    background-color: #f5f5f5;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 24px;
      line-height: 32px;
      color: rgb(34, 34, 34);
      font-weight: 500;
    }

    .closeWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  .contentWrap {
    .orderContentWrap {
      margin-bottom: 24px;

      .orderContentBody {
        display: flex;

        .leftWrap {
          flex: 1;
        }

        .rightWrap {
          flex: 1;
        }
        .leftWrap, .rightWrap{
          .ant-descriptions-item{
            &-label{
              color: #252525;
            }
          }
          .ant-descriptions-row{
            &:nth-child(odd){
              .ant-descriptions-item{
                padding-bottom: 3px;
              }
            }
          }
        }
      }

      .orderContentPaymentInfo{
        .payment-info-tax-exempt{
          margin-left: 5px;
          border: 1px solid #cccccc;
          padding: 2px 5px;
          border-radius: 5px;
          line-height: 19px;
        }
        .ant-descriptions-item{
          padding-bottom: 0px;
          &-label, &-content{
            font-size: 16px;
          }
        }
      }
      .payment-info-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        line-height: 22px;
        &:nth-last-child(1){
          font-weight: 600;
        }
      }
    }

    .payment-info-red {
      color: #E10013 !important;
    }

    .ant-card-head {
      border-bottom: 0;
    }

    .ant-card-body {
      padding-top: 10px;
    }
  }

  .cardNo {
    color: #252525
  }
}
@media (max-width: 767px) {
  .orderReportDetail {
    padding: 0 12px;
    .contentWrap{
      .ant-card-body{
        padding: 10px;
      }
      .orderContentWrap{
        margin-bottom: 15px;
      }
    }
    .titleWrap{
      padding-top: 5px;
      .title{
        font-size: 18px;
      }
    }
  }
}