@import "/src/styles/mixin";

.breaks-container {
  .ant-drawer-header {
    display: none !important;
  }
  
  .ant-drawer-body {
    padding: 0px !important;
  }
}
.breaks-edit-content{
  background: rgb(249 249 249);
  padding: 24px;
  height: 100vh;

  &-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-wrap{
    margin-top: 24px;
    padding: 16px;
    background: rgb(255 255 255);
    border-radius: 8px;
  }
}


