.create-modifier-group-table{
  margin: 15px;
  padding: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  &-title{
    min-height: 46px;
    font-size: 16px;
    font-weight: 600;
  }
  .ant-table-cell{
    &::before{
      display: none;
    }
    .show-list{
      background-color: #fff;
    }
  }
  .expand-row{
    background-color: #fff;
    &:hover{
      background-color: #fff;
    }
    &.ant-table-expanded-row{
      & > .ant-table-cell{
        padding: 0;
        background-color: #fff;
        .modifier-tree {
          padding-top: 16px;
          padding-left: 48px;
          padding-bottom: 24px;
        }
        &:hover{
          background-color: #fff;
        }
      }
    }
  }
}