html, body {
  height: 100%;
  overscroll-behavior: none;
}
body {
  min-width: 1000px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root{
  height: 100%;
}
.App{
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* .ant-menu-item-selected {
  background: linear-gradient(286deg, #FFC60C 1%, #FFC60C 1%, #FFD100 100%)!important;
  color: #222222!important;
} */
.ant-menu-submenu-popup .ant-menu-vertical {
  box-shadow: none;
}
#webpack-dev-server-client-overlay-div {
  width: 0vw !important;
  height: 0vh !important;
}

.steamer-main-top{
  height: 36px;
  padding: 24px 40px;
  background: #ffffff;
  display: flex;
  justify-content:flex-end;
}


.logo{
  display: flex;
  color: #ffffff;
  justify-content: left;
  align-items: center;
  padding: 16px;
}
.logo span{
  display: inline-block;
  line-height: 22px;
  font-family: SF Pro Text;
  font-size: 18px;
  font-weight: 600;
  opacity: 1;
  margin-left: 3px;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),margin 0.3s,color 0.3s;
}

.logo img{
  display: inline-block;
  width: auto;
  height: 34px;
}
.logo .logo-background {
  background: linear-gradient(286deg, #FFC60C 1%, #FFC60C 1%, #FFD100 100%)!important;
  padding: 0 5px;
  border-radius: 4px;
  color: #222222;
}
.MenuUnfoldOutlined{
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.ant-layout{
  height: 100%;
}
.ant-layout-content{
  overflow-y: auto;
  padding: 0 84px 0 24px !important;
  position: relative;
}

.apaas-container{
  height: 100%!important;
}
.ant-tabs-content-holder{
  height: calc(100% - 48px)!important;
  overflow: scroll;
}
.apaas-Menu-menuTabList_pc{
  height: 100%;
}
.ant-tabs{
  height: 100%;
}
.drawer-content{
  padding: 0!important;
}

.ant-tabs-nav-wrap {
  padding-left: 0 !important;
}

/* 全局tab样式 */
.ant-tabs>.ant-tabs-nav {
  position: sticky !important;
  top: 0px;
  z-index: 10;
  background-color: #ffffff;
}

.lowcode-runtime {
  height: 100%;
}

.ant-tabs, .ant-tabs-top {
  height: 100%;
  overflow: hidden;
}

.ant-tabs-tabpane {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.drawer {
  background-color: #f5f5f5 !important;
}
.ant-drawer-content-wrapper {
  background-color: #f5f5f5 !important;
}

/* 直接针对最后一个面包屑项设置样式 */
.ant-breadcrumb ol li:last-child a {
  color: rgba(0, 0, 0, 0.88);
}


body,ol,ul,h1,h2,h3,h4,h5,h6,p,th,td,dl,dd,form,fieldset,legend,input,textarea,select{
  margin: 0;
  padding: 0;
}


.ml10 {
  margin-left: 10px;
}

.ml4 {
  margin-left: 4px;
}

.mt4 {
  margin-top: 4px;
}

.mt12 {
  margin-top: 12px;
}

.mt16 {
  margin-top: 16px;
}

.mt20 {
  margin-top: 20px;
}

.mt24 {
  margin-top: 24px;
}

.mt32 {
  margin-top: 32px;
}


.mr64 {
  margin-right: 64px;
}
@media (max-width: 767px) {
  body{
    min-width: 100vw;
  }
  .ant-layout-content{
    padding: 0 15px !important;
    margin-bottom: 0 !important;
  }
}