.pickup-hours-form-item {
    .ant-form-item-explain-error {
        padding-left: 382px;
    }

    
}

.pickup-hours-item {
    .week-day{
        color: var(--M3-Text-On-Surface, #313131);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px; /* 122.222% */
    }
    display: grid;
    grid-template-columns: 150px 200px 300px;
    grid-gap: 16px;
    align-items: flex-start;

    & + .pickup-hours-item {
        margin-top: 8px;
    }
    .interval-times-group {
        display:'flex';
        flex-direction:'column';
        .interval-times-item {
            display: flex;
            margin-bottom: 5px;
            .operation-icon-container {
                margin-left: 24px;
                padding-top: 1em;
            }
        }
    }
}
.pickup-hours-label {
    font-weight: bold;
}