.add-time-sheet{
  &-modal{
    .ant-modal-content{
      .ant-modal-body{
        .ant-picker{
          width: 100%;
        }
      }
    }
  }
}
.time-sheet-date-picker-popup{
  .ant-picker-content{
    .ant-picker-cell{
      .ant-picker-cell-inner {
        &::before {
          border: 1px solid transparent;
        }
      }
      .ant-picker-cell-inner-today{
        position: relative;
        &::before {
          position: absolute;
          top: 0;
          inset-inline-end: 0;
          bottom: 0;
          inset-inline-start: 0;
          z-index: 1;
          border: 1px solid #2563eb;
          border-radius: 4px;
          content: "";
        }
      }
    }
  }
}

.breaksListBox {
  display: flex;
  width: 650;
  margin-bottom: 20px;

  .ant-form-item {
    margin-bottom: 0 !important;
  }
}