@import './index.scss';
a{
  color: $primaryColor;
  transition: .3s;
  &:hover {
    color: $primaryColor;
    opacity: 0.7;
  }
}
:root {
  --antd-theme-color: $primaryColor;
  --primary-hover-color: #7aadff;
}

.ant-table {
  .ant-table-thead {
    .ant-table-cell {
      padding-top: 8px;
      padding-bottom: 8px;

      &.ant-table-column-sort{
        background: #fafafa;
      }
    }
  }


  .ant-table-summary {
    .ant-table-cell {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .ant-table-row-selected{
    .ant-table-cell{
      background: white !important;
    }

    .ant-table-cell-row-hover{
      background: #fafafa !important;
    }
  }
}

.ant-table-filter-dropdown {

  .ant-dropdown-menu-item {
    padding: 9px 16px !important;

    .ant-radio-wrapper {
      margin-right: 24px;
    }

    &.ant-dropdown-menu-item-selected {
      background-color: transparent;

      &:hover {
        background-color: rgb(0 0 0 / 4%);
      }
    }
  }

  .ant-table-filter-dropdown-btns {
    border-top: 0 !important;
  }
}

.ant-btn {
  font-weight: 500;

  .ant-wave {
    display: none;
  }

  &.ant-btn-link {
    &:active > span {
      opacity: 0.5;
    }
  }
}

.ant-modal-root {
  .ant-modal-header {
    .ant-modal-title {
      margin: 0;
      color: #000;
      font-weight: 500;
      font-size: 20px;
      line-height: 1.375;
      word-wrap: break-word;
    }
  }

  .ant-modal-content {
    padding-top: 16px;
    border-radius: 16px;

    .ant-modal-close {
      position: absolute;
      top: 8px;
      right: 13px;
      z-index: 10;
      padding: 0;
      color: #999;
      font-weight: 700;
      line-height: 1;
      text-decoration: none;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
      transition: color .3s;

      &:hover {
        color: rgb(0 0 0 / 75%);
        text-decoration: none;
      }

      .ant-modal-close-x {
        display: block;
        font-size: 20px;
        font-style: normal;
        text-align: center;
        text-transform: none;
        text-rendering: auto;

        .ant-modal-close-icon {
          font-size: 12px;
        }
      }
    }

    .ant-modal-footer {
      margin-top: 0;

      .ant-btn {
        min-width: 123px;
      }
    }
  }
}

.ant-table-placeholder{
  z-index: 100!important;
}
.ant-checkbox-wrapper{
  .ant-checkbox{
    margin-top: 4px;
    align-self: flex-start;
  }
}
.m-title {
  position: sticky;
  top: 0;
  z-index: 10;
  height: 60px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #DDDDDD;
  .title {
    font-size: 18px;
  }
}
.ant-tabs{
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
}
@media (max-width: 767px) {
  .m-title {
    height: 40px;
    line-height: 40px;
    .title{
      font-size: 14px;
    }
  }
  .ant-table-pagination-right{
    justify-content: center!important;
  }
  .ant-btn{
    padding: 4px 8px;
  }
}
@media (max-width: 440px) {
  .ant-tabs .ant-tabs-tab+.ant-tabs-tab{
    margin-left: 10px;
  }
  .filterWrap{
    .right-wrap{
      align-items: flex-end !important;
    }
  }

  .main-tab-title, .help-box .title{
    font-size: 14px;
  }
  .ant-tabs .ant-tabs-tab{
    font-size: 14px;
  }
  .filter-wrap{
    .ant-form-item{
      margin-bottom: 10px;
    }
  }
}