.menu-events{
  .ant-form-item-label{
    font-weight: 600;
  }
  .pickup-hours-item{
    display: flex;
    align-items: center;
    .week-day{
      width: 100px;
      padding-right: 20px;
    }
  }
}