.employeeListJobs {
  .filterWrap {
    display: flex;
    justify-content: space-between;

    .inputName {
      width: 300px;
      margin-right: 10px;
    }
  }

  .tableWrap {
    margin-top: 20px;

    .colStatus {
      display: flex;
      align-items: center;

      .greenPoint {
        background-color: #00b578;
        border-radius: 6px;
        width: 6px;
        height: 6px;
        margin-right: 5px;
      }

      .greyPoint {
        background-color: rgb(0 0 0 / 20%);
        border-radius: 6px;
        width: 6px;
        height: 6px;
        margin-right: 5px;
      }
    }

    .colEmployees {
      .ant-btn-link {
        padding: 0;
        color: rgb(255 39 39);

        &:disabled {
          color: rgb(0 0 0 / 25%)
        }
      }
    }

    .colActions {
      display: flex;

      .btnEdit {
        margin-right: 10px;
      }

      .text {
        margin-left: 5px;
      }

      .ant-btn-link {
        display: flex;
        align-items: center;
        padding: 0
      }
    }

  }
  @media (max-width: 767px) {
    .filterWrap{
      .inputName {
        width: auto;
        margin-bottom: 10px;
      }
    }
    .add-btn{
      position: fixed;
      top: 65px;
      right: 16px;
      z-index: 10;
    }
  }
}