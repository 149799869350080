@import '@/styles/index';
.home-page {
  width: 100%;
  height: 100%;
}

.home-welcome-name {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: #000;
}

.home-welcome-date {
  text-align: right;
  line-height: 32px;
}

.home-welcome-date > span {
  margin: 0 4px;
}

.home-welcome-date-text {
  color: #000;
}

.home-welcome-date-rotate {
  font-size: 14px !important;
  color: $primaryColor;
  position: relative;
  top: -1px;
}

.home-actions {
  width: 100%;
  height: 190px;
  border: 1px solid #E8E8E8;
  border-radius: 6px;
  margin: 24px 0;
  transition: .3s;
}

.home-actions-title {
  width: 100%;
  height: 22px;
  margin: 0;
  padding: 16px 24px;
}

.home-actions .ant-col-6 {
  height: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #E8E8E8;
}

.home-actions .ant-col-6:last-child {
  border-right: none;
}

.home-actions-text {
  font-size: 18px;
  font-weight: 500;
  color: #222;
  line-height: 40px;
}

.home-actions-icon {
  display: inline-block;
  width: 40px !important;
  color: #222;
  margin: 0 16px;
}

.home-data-list{
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
}

.home-data-list > .ant-row {
  flex: 1;
}

.home-dataShow {
  flex: 1;
  height: 165px;
  border: 1px solid #E8E8E8;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 32px 40px;
  transition: .3s;
}

.home-dataShow:nth-child(1), .home-dataShow:nth-child(2) {
  margin: 0 20px 0 0;
}

.home-dataShow span {
  font-size: 40px;
  line-height: 32px;
  font-weight: 500;
  transition: .3s;
  color: #222;
}

.home-dataShow .ruloData {
  font-size: 16px;
  font-weight: normal;
  color: #c6c6c6;
}

.home-dataShow .home-dataShow-icon {
  font-size: 16px;
  line-height: 32px;
  color: #999;
  margin: 0 4px;
  position: relative;
  top: 1px;
}

.orderBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-footer-img {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* justify-content: center; */
  margin-top: 90px;
}

.home-footer-img> p {
  margin-top: 24px;
  font-size: 16px;
}

.home-footer-icon {
  display: inline-block;
  width: 120px !important;
}

.goingtoRight {
  cursor: pointer;
  span{
    font-size: 16px;
    color: #555;
  }
}

.popover-style {
  color: white;
  background-color: rgb(0 0 0 / 50%);
}
@media (max-width: 1200px) {
  .home-actions{
    height: 150px;
  }
  .home-dataShow{
    height: 120px;
    padding: 20px;
    span{
      font-size: 20px;
    }
  }
  .home-actions-icon{
    width: 32px !important;
  }
  .home-welcome-name{
    font-size: 16px;
  }
  .home-actions-text{
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .home-page{
    padding: 5px 10px;
  }
  .home-welcome-name, .home-welcome-date{
    max-width: 100%;
  }
  .home-dataShow{
    padding: 12px;
  }
  .home-actions{
    height: auto;
    .home-actions-text{
      line-height: 20px;
    }
    .home-actions-title{
      display: none;
    }
  }
  .home-info{
    flex-direction: column;
  }
  .home-actions, .home-data-list{
    margin: 10px 0;
  }
  .home-data-list{
    flex-direction: column;
  }
  .home-dataShow:nth-child(1), .home-dataShow:nth-child(2){
    margin: 0 0 10px;
  }
  .goingtoRight{
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -10px;
  }
}