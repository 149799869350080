.net-sales-summary-by-days-of-week {
  margin-top: 16px;
  padding: 16px;
  border-radius: 6px;
  background: white;

  .net-sales-summary-by-days-of-week-header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .net-sales-summary-by-days-of-week-title-wrap {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      white-space: pre;
    }
  }

  .charts-wrap {
    margin-top: 16px;

    .g2-tooltip {
      border-radius: 8px !important;
    }
  }
  @media (max-width: 767px) {
    padding: 10px 5px;
    .net-sales-summary-by-days-of-week-header-wrap .net-sales-summary-by-days-of-week-title-wrap {
      padding-left: 10px;
      font-size: 16px;
    }
  }
}