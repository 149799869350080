.table-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
  gap: 16px;
  
  &-left {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
  }
  
  &-right {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .export-wrap {
    position: fixed;
    right: 85px;
    top: 80px;
    z-index: 20;
  }
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    
    &-left,
    &-right {
      width: 100%;
      justify-content: flex-start;
    }
  }
} 