.promotion-edit {
  padding: 24px;
  .header-wrap {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 24px;
      color: rgb(61, 61, 61);
      font-weight: 500;
    }

    .actions {
      display: flex;
      gap: 12px;
    }
  }

  .form-wrap {
    background-color: #fff;
    margin-top: 16px;
    padding: 24px;
    border-radius: 16px;
    .required {
      color: red;
      margin-left: 2px;
    }

    .ant-form-item {
      margin-bottom: 16px;
    }

    .ant-form-item-control-input {
      min-height: 22px;
    }

    .rounding-type-radio-group {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      label {
        border-radius: 6px;
      }

      .ant-radio-button-wrapper {
        border-inline-start-width: 1px;
      }

      .ant-radio-button-wrapper-in-form-item {
        &:before {
          content: none;
        }
      }
    }
  }
}