@import '@/styles/index';
.pre-modifier {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;

    .title {
      display: flex;
      align-items: center;
      height: 58px;
      font-size: 18px;
      font-weight: 600;
    }
  }

  &-tips {
    width: 60%;
    color: #4C4C4C
  }

  .table-wrap {
    margin-top: 20px;
  }
}