.employeeListEmployees {
  .filterWrap {
    display: flex;
    justify-content: space-between;

    .inputName {
      width: 300px;
      margin-right: 10px;
    }
  }

  .tableWrap {
    margin-top: 20px;

    .colStatus {
      display: flex;
      align-items: center;

      .greenPoint {
        background-color: #00b578;
        border-radius: 6px;
        width: 6px;
        height: 6px;
        margin-right: 5px;
      }

      .greyPoint {
        background-color: rgb(0 0 0 / 20%);
        border-radius: 6px;
        width: 6px;
        height: 6px;
        margin-right: 5px;
      }
    }

    .col-account-created {
      text-align: center;
      .text {
        margin-left: 5px;
      }

      .ant-btn-link {
        padding: 0
      }
    }

    .colFirstName {
      display: flex;
      align-items: center;

      .text {
        margin-left: 5px;
      }
    }

    .colActions {
      display: flex;

      .btnEdit {
        margin-right: 10px;
      }

      .text {
        margin-left: 5px;
      }

      .ant-btn-link {
        display: flex;
        align-items: center;
        padding: 0
      }
    }

  }
  @media (max-width: 767px) {
    .filterWrap{
      flex-direction: column;
      .inputName{
        width: 100%;
      }
      .add-btn{
        position: fixed;
        top: 65px;
        right: 10px;
        z-index: 10;
      }
    }
  }
}