.pickup-url-dialog {
  // 标题区域样式
  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .title {
      font-size: 24px;
      margin: 0;
    }

    .close-icon {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }

  // 警告文本样式
  .warning-text {
    padding-bottom: 10px;
  }

  // URL 输入区域样式
  .url-input-container {
    display: flex;
    border: 1px solid #DDDDDD;
    border-radius: 6px;
    overflow: hidden;
    height: 48px;

    .base-url {
      background-color: #eae8ed;
      padding: 8px 20px;
      font-size: 14px;
      color: #787A85;
      display: flex;
      align-items: center;
    }

    .editable-url {
      margin: 0;
      flex: 1;

      .ant-input {
        border: none;
        border-radius: 0;
        height: 48px;
        line-height: 48px;
        padding: 8px 20px 8px 6px;
      }
    }
  }

  // 错误信息样式
  .err-message {
    margin-top: 10px;
    margin-bottom: 10px;

    .err-text {
      color: #ff4d4f;  // 保持文字为红色
    }
  }
}

