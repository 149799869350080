.fee-wrap {
  min-width: 1150px;

  .title-wrap {
    height: 57px;
    font-size: 18px;
    background: white;

    .title {
      height: 100%;
      margin: 0 22px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #DDDDDD;
    }
  }
  .filter-wrap {
    position: sticky;
    top: 60px;
    z-index: 99;
    background-color: #f9f9f9;
    padding: 10px 0;
    margin-top: -10px;
  }

  .bg-content {
    background: #F9F9F9;
    padding: 24px 22px 22px 22px;
    border-radius: 8px;
    .time-info {
      text-align: right;
    }
  }

  .action {
    display: flex;

    span {
      cursor: pointer;
      height: 24px;
    }
  }

  .summary-wrap {
    margin-top: 8px;
    display: flex;
    font-family: Roboto, sans-serif;
    padding: 32px 0;
    background: #fff;
    border-radius: 6px;

    .summary-item {
      padding: 0 32px;
      width: 200px;
      border-right: 1px solid #D8D8D8;

      &.last {
        border-right: none;
      }
    }

    .item-title {
      display: flex;
      align-items: center;
      font-family: "PingFang SC";
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      color: #999;
    }

    .item-price-large-bold {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      color: #222;
    }

    .item-dollar-large-bold {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 900;
      line-height: 32px;
      color: #000;
    }

    .price-wrap {
      display: flex;
      align-items: center;
    }
  }

  .chart-wrap {
    padding: 20px 0;
    display: flex;
    gap: 0 16px;
    .tab {
      background: #f0f0f0;
      height: 40px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 6px;
      margin: 10px 0;
      cursor: pointer;
      box-sizing: border-box;
      cursor: pointer;
      .tab-item{
        width: 30%;
        text-align: center;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        font-weight: 500;
        color: #999;
        cursor: pointer;
        &.active{
          border-radius: 6px;
          background: #fff;
          color: #000;
        }
      }
    }
    .chart-item {
      flex: 1;
      padding: 20px 20px 0 20px;
      height: 380px;
      background: #fff;
      border-radius: 6px;

      p {
        margin-right: 8px;
        font-family: "PingFang SC";
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        color: #222;
      }

      .chart-content {
        padding-top: 10px;
        display: flex;
        justify-content: center;

        .chart-default {
          canvas {
            cursor: default;
          }
        }
      }
    }

    .g2-tooltip-list-item {
      .g2-tooltip-list-item-name {
        align-items: flex-start !important;

        .g2-tooltip-list-item-marker {
          margin-top: 5px;
        }

        .g2-tooltip-list-item-name-label {
          margin-left: 3px;
          word-break: break-all !important;
          overflow-wrap: break-word !important;
          white-space: pre-line !important;
          line-height: 16px;
        }
      }

      .g2-tooltip-list-item-value {
        display: none !important;
      }
    }
  }

  .table-wrap {
    background: #fff;
    padding: 20px;
    border-radius: 6px;

    .table-title-wrap {
      margin-right: 8px;
      font-family: "PingFang SC";
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      color: #222;
      margin-bottom: 20px;
    }

    .table-content {
      &.noData {
        :global {
          .ant-table-expanded-row-fixed {
            margin: 0 !important;
          }
        }
      }

      .row {
        line-height: 48px;

        td {
          padding-top: 0;
          padding-bottom: 0;
          border-bottom: 0;
        }
      }

      :global {
        .ant-table-body {
          overflow: auto !important;
        }

        .ant-table-thead {
          th {
            border-bottom: 0
          }
        }
      }
    }
  }

  .tooltip-warp {
    display: flex;

    .tooltip-point {
      margin-top: 7px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }

    .tooltip-text {
      margin-left: 8px;
      font-size: 13px;
      white-space: break-spaces;
    }
  }
  @media (max-width: 767px) {
    min-width: 100%;
    margin: 0 -15px;
    .m-title{
      margin-bottom: 0;
      padding-left: 12px;
    }
    .chart-wrap{
      flex-direction: column;
      .chart-item{
        padding: 10px;
        .amount{
          font-size: 16px;
        }
        p{
          text-align: center;
          font-size: 16px;
        }
      }
    }
    .filter-wrap{
      top: 40px;
    }
    .fee-report-summary-content{
      gap: 0 20px;
      padding: 12px;
      .item-wrap {
        &+ .item-wrap:before{
          display: none;
        }
        .amount{
          font-size: 16px;
        }
      }
    }
    .chart-content{
      transform: scale(0.8);
    }
    .bg-content{
      padding: 10px;
      border-radius: 0;
      .time-info{
        margin: 10px 0;
      }
    }
    .table-wrap{
      padding: 10px;
      .table-title-wrap{
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }
}
