.balance-wrapper {
    padding: 0 24px;
    background-color: #f6f6f6;
    .balance-title-wrapper {
        height:80px;
    }
    // 内容显示容器
    .content-wrapper {
        background-color: #fff;
        padding: 16px
    }
}
.heading2 {
    font-size: 24px;
    font-style: normal;
    line-height: 32px; 
}
.heading4 {
    font-size: 16px;
    font-style: normal;
    line-height: 24px; 
}
.heading5 {
    font-size: 14px;
    font-style: normal;
    line-height: 22px;
}
.body-base {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
