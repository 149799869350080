.info-detail{
  font-family: Nunito Sans;
  min-width: 300px;
  &-head{
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-end;
  }
  &-body{
    &-descriptions{
      &-row{
        margin-bottom: 15px;
        .lorem-line-break{
          color: red;
        }
        .ant-form-item{
          margin-bottom: 0px;
        }
        .ant-form-item-row{
          .ant-form-item-label{
            padding: 0px;
            label{
              height: auto;
              line-height: 22px;
              font-size: 18px;
            }
          }
          .ant-form-item-control{
            margin-top: 8px;
            width: 600px;
          }
        }
        .ant-form-item-control{
          &-input{
            min-height: auto !important;
            &-content{
              line-height: 22px;
              font-size: 18px;
            }
          }
        }
      }
      &-title{
        color: rgb(0, 0, 0);
        font-size: 18px;
        display: block;
      }
      &-material{
        margin-top: 16px;
        &-item{
          margin-bottom: 16px;
          .ant-checkbox-group{
            width: 80%;
          }
          .ant-form-item-control-input{
            min-height: 22px !important;
          }
          .ant-form-item{
            margin-bottom: 0px;
          }
          .ant-col{
            display: flex;
            align-items: center;
            .ant-form-item{
              flex: 1;
            }
            .ant-form-item-control-input, .ant-checkbox-group{
              width: 100%;
            }
          }
        }
        &-subtitle{
          max-width: 640px;
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 16px;
        }
      }
    }
    .title {
      font-size: 18px;
      font-weight: 600;
    }
  }
}