@import '@/styles/index';
.offline-config {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;

    .title {
      display: flex;
      align-items: center;
      height: 58px;
      font-size: 18px;
    }
  }
  .row-container{
    display: flex;
    height: 28px;
    line-height: 18px;
    align-items: center;
  }
  .name{
    font-size: 18px;
    margin-right: 20px;
    margin-bottom: 10px;
    margin: 30px 20px 10px 0;
  }
  .desc{
    line-height: 16px;
    color: #616368;
    margin-bottom: 20px;
  }
  .error{
    line-height: 16px;
    color: red;
    line-height: 22px;
    margin-top: 10px;
  }
  .input-name{
    line-height: 16px;
    width: 130px;
  }

}