.user-info-total {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background-color: #f5f5f5;
  .user-labor-top {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    
    .title {
      width: 280px;
      flex-shrink: 0;
    }
    
    .top-info {
      display: flex;
      flex-grow: 1;
      overflow-x: auto;
      justify-content: space-around;
      .item {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        .ant-divider-vertical{
          height: 30px;
          margin: 0 16px;
        }
        .ant-statistic {
          min-width: 100px;
        }
        
        .ant-statistic-title {
          white-space: nowrap;
          color: #616368;
        }
        
        .ant-statistic-content {
          white-space: nowrap;
          font-size: 16px;
        }
      }
    }
  }
  .ant-table-tbody > tr.ant-table-row.ant-table-row-level-1:hover {
    cursor: pointer;
  }
  .table-expand-icon{
    width: 24px;
    height: 24px;
    display: inline-block;
    text-align: center;
    line-height: 24px;
    vertical-align: middle;
    transition: 0.3s;
  }
}
@media print{
  .user-labor-top{
    .title{
      width: 150px!important;
      flex-shrink: 1;
    }
    .top-info{
      overflow: hidden;
    }
  }
}