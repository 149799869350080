@import "/src/styles/mixin";

.employee-create-content{
  padding: 24px;

  &-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-wrap{
    margin-top: 24px;
    padding: 16px;
    background: rgb(255 255 255);
    border-radius: 8px;
  }

  &-employeeForm,
  &-jobForm{
    margin-top: 12px;
    font-size: 14px;

    .ant-form-item-label{
      min-width: 150px;
      text-align: left;
      flex: none;
      max-width: none;
    }

    .ant-form-item-control{
      width: 550px;
      flex: none;
      max-width: none;

      .access-code-addon-after{
        cursor: pointer;

        &:hover{
          text-decoration: underline;
        }
      }
    }

    &-tips{
      color: rgb(153 153 153);
      font-size: 14px;
    }

   
  }
}

.employee-create-content-jobForm {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}