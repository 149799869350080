.availability-price{
  .ant-picker-outlined{
    width: 100%;
  }
  .table-header{
    display: grid;
    grid-template-columns: 5fr 5fr 5fr 1fr;
    gap: 16px;
    padding: 16px;
    font-weight: bold;
    background-color: #f5f5f5;
  }
  .table-body{
    display: grid;
    gap: 16px;
    padding: 0 16px;
    grid-template-columns: 5fr 5fr 5fr 1fr;
    .ant-form-item{
      margin-bottom: 0;
    }
  }
}