@import "/src/styles/mixin";

.forgot-password{
    width: 370px;

    &-title{
        font-size: 32px;
        font-weight: 600;
        line-height: 36px;
        color: rgb(34 34 34);
        display: inline-block;
        vertical-align: middle;
    }

    &-tips{
        font-size: 14px;
        padding: 12px;
        text-align: center;
        display: inline-block;
        margin-top: 32px;
        color: rgb(153 153 153);
    }

    .mterial-form{
        margin-top: 40px;
        width: 100%;

        .ant-form-item-control-input-content{
            display: flex;
            justify-content: space-between;

            .ant-btn{
                flex: 0 0 48%;
            }
        }
        @include ant-form-item;
        @include reset-btn;
    }
    @media (max-width: 767px) {
        .forgot-password-tips, .mterial-form{
            margin-top: 10px;
        }
        .mterial-form{
            padding: 0 15px;
        }
    }
}