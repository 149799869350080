@import '../mixin.scss';
.service-charge-section{
  @include reportSectionGap{
    &-item{
      display: flex;
      justify-content: space-between;
      .preview-ui-text-line, .preview-ui-text-line-new{
        &:nth-child(1){
          width: 250px;
        }
        &:nth-child(3){
          width: 65px;
          text-align: end;
        }
      }
    }
  }
}