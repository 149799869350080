
.body-base{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
}   
.content-wrapper {
    background-color: #fff;
    padding: 16px;
}
.heading5 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; 
}
.heading4 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px
}