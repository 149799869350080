.decoration-print-out {

  .decoration-container {
    display: flex;
    .config-container {
      width: 600px;
      margin-right: 20px;
    }

    .preview-container {
      width: 530px;
      padding: 32px 24px;
      border: 1px solid #BEBEBE;
    }

    .note-info {
      width: 530px;
      color: '#000';
      opacity: 0.7;
      margin-top: 10px;
    }
  }
}