@import "/src/styles/mixin";

.login-comp{
    width: 350px;
    text-align: center;

    &-logo{
        display: inline-block;

        img{
            display: block;
            width: 100%;
            margin-top: 19px;
        }
    }

    .mterial-form{
        margin-top: 40px;
        width: 100%;
        @include ant-form-item;
    }
    .login-btn{
        border-radius: 4px;
        margin-top: 12px;
        width: 100%;
        height: 48px
    }
    @media (max-width:767px) {
        .mterial-form{
            width: 330px;
            margin: 0 auto;
        }
    }
}