.gift-card-summary {
  padding: 16px 8px;
  border-radius: 6px;
  background: #F5F5F5;
  min-height: 460px;
  .table-toolbar {
    padding: 0 8px;
  }
  @media (max-width: 768px) {
    padding: 8px;
  }
} 