.net-sales-summary {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 16px;
  flex-wrap: wrap;

  .net-sales-summary-item-wrap {
    flex: 1 0 calc(50% - 8px); 
    border-radius: 6px;
    background: white;
    padding: 24px;

    .net-sales-summary-title-wrap {
      display: flex;
      align-items: center;
      height: 24px;

      .net-sales-summary-main-title {
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
      }

      .net-sales-summary-tip-icon {
        margin-left: 10px;
        font-size: 20px;
        color: #55565E;
      }
    }

    .net-sales-summary-main-amount {
      margin-top: 6px;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }

    .sub-items-wrap {
      display: flex;
      margin-top: 10px;
      justify-content: space-between;

      .sub-item {
        .sub-title {
          white-space: nowrap;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }

        .sub-amount {
          margin-top: 6px;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    .net-sales-summary-item-wrap{
      padding: 10px;
      .sub-items-wrap{
        display: grid;
        gap: 0 10px;
        grid-template-columns: 1fr 1fr 1fr;
        .sub-item .sub-amount{
          font-size: 16px;
        }
        .sub-item:before{
          display: none;
        }
      }
      .net-sales-summary-main-amount{
        font-size: 16px;
      }
    }
  }
}

@media print {
  .net-sales-summary {
    overflow: visible !important;
  }
}