@import "../index.scss";

.modifier-report {
  margin-top: 15px;
  background-color: #ffffff;
  padding: 24px 16px;
  border-radius: 8px;
  .ant-form-item{
    margin-bottom: 0 !important;
  }
}
