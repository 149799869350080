@import '@/styles/index';
.sales-summary-service-charge-list {
  margin-top: 16px;
  padding: 16px;
  border-radius: 6px;
  background: white;

  .header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sales-list-title-wrap {
      display: flex;
      align-items: center;
      margin-left: 10px;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      white-space: pre;
    }

    .actions-wrap {
      .icon-table{
        color: $primaryColor;
      }
    }
  }

  .net-sales-list-table-wrap {
    margin-top: 16px;

    .empty-table {
      .ant-table-thead {
        .ant-table-cell {
          height: 39px;
        }
      }

      .ant-table-tbody {
        display: none !important;
      }
    }

    .sticky-row {
      position: sticky;
      bottom: 0;
      font-weight: 500;

      td {
        background: #F9F9F9 !important;
      }
    }

    &.no-data {
      .ant-table-expanded-row-fixed {
        margin: 0 -16px !important;
      }
    }

    .ant-table-body {
      overflow: auto !important;
    }

    .ant-table-summary {
      .ant-table-cell {
        padding-top: 26px;
        padding-right: 31px;
        padding-bottom: 26px;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 5px;
    .header-wrap .sales-list-title-wrap{
      font-size: 16px;
    }
    .net-sales-list-table-wrap{
      margin-top: 0;
    }
  }
}