.employee-checkout-report{

  .sticky-row {
    position: sticky;
    bottom: 0;
    td {
      background: #F5F5F5 !important;
    }
  }
  .filter-wrap {
    position: sticky;
    top: 57px;
    z-index: 99;
    background-color: #fff;
    padding: 10px 0;
  }
  .ant-table-cell{
    max-width: 350px;
  }
  .print-main{
    .print-info{
      display: none;
    }
  }
  @media (max-width: 767px) {
    .filter-wrap {
      top: 49px;
    }
  }
  @media (max-width: 440px) {
    .filter-wrap {
      top: 46px;
    }
  }
}
@media print {
  .print-main{
    padding: 20px;
    .print-info{
      display: block;
      .print-info-title{
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
    .ant-table-wrapper table {
      width: 100% !important;
    }
    .ant-table-wrapper {
      overflow: hidden !important;
    }

    .ant-table-content {
      overflow: hidden !important;
    }

    .ant-table-expanded-row-fixed {
      width: 100% !important;

      .ant-empty-image {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ant-empty-description {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}