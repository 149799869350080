@import "/src/styles/mixin";

.info-container{
    min-height: 400px;
    position: relative;

    &-title{
        margin-bottom: 20px;
        height: 58px;
        font-size: 18px;
        font-weight: 500;
        line-height: 58px;
        letter-spacing: 0;
        color: rgb(34 34 34);
        border-bottom: 1px solid rgb(0 0 0 / 10%);
    }

    &-setting{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .typography{
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0;
            color: rgb(61 61 61);
        }
    }

    &-description{
        font-weight: 400;
        margin-top: 28px;
        opacity: 0.83;
        font-size: 14px;
        line-height: 22px;

        .ant-form-item-label-item-label{
            text-align: left;
        }
    }
}