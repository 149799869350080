.printers-and-cash-drawers{
    background-color: #fff;
    &-head{
        display: flex;
        justify-content: space-between;
        &-title{
            font-size: 18px;
            font-weight: 500;
        }
    }
    &-body{
        margin-top: 15px;
        .btn-group{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}