@import "../index.scss";

.sub-menus-channel-availability {
  @include menus-card-style;
  .pos-service-area{
    padding-left: 20px;
    .switch-box{
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .text{
        margin: 0 10px;
      }
      .ant-form-item{
        margin-bottom: 0;
      }
    }
  }
}