.modifier-tree{
  .search-box{
    margin-top: 10px;
    display: grid;
    grid: minmax(39px, auto) / 1fr auto;
    grid-gap: 16px;
  }
  .menus-add-modifier-group{
    .title{
      display: none; 
    }
  }
  .drag-item{
    display: flex;
    padding-left: 0;
    margin: 0;
    .drag-button{
      color: #000;
      .anticon{
        font-size: 14px;
      }
      &:hover{
        color: #000;
      }
    }
  }
  .modifier-item{
    flex: 1;
    .li{
      display: grid;
      grid: minmax(39px, auto) / 1fr auto auto;
      grid-gap: 16px;
      line-height: 39px;
    }
    .children{
      padding-left: 36px;
    }
  }
}